
import { Routes, Route } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './layout.css';
import "nes.css/css/nes.min.css";

import Home from './routes/home';
import Login from './routes/login';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/romlist" element={<Home/>} />
      <Route path="/notice" element={<Home/>} />
      <Route path="/notice/:id" element={<Home/>} />
      <Route path="/Login" element={<Login/>} />
    </Routes>
  );
}

export default App;
