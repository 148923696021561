
import {useEffect,useState} from "react";
import { useNavigate } from "react-router-dom";
import PocketBase from 'pocketbase';
import Header from "../components/header"
import Main from "../components/main"

import Info from '../info'
const info = Info()
const pb = new PocketBase(info.api);

function Home() {

    const [loading,setLoading] = useState(true)
    let navigate = useNavigate();
   
    useEffect(()=>{

        const checkToken = async ()=>{

            try {
    
                console.log('------ authRefresh');
                await pb.collection('users').authRefresh();
    
                console.log(pb.authStore.isValid);
                if(!pb.authStore.isValid){
                    navigate("/Login"); 
                    return ;
                }
                console.log(pb.authStore.token);
                console.log(pb.authStore.model.id);

                setLoading(false);
            }
            catch(e){
                console.log('----------'+e);
                navigate("/Login");   
            }
        }

        checkToken();
        
    },[navigate])

    return (
        <div>
            <Header />
            {loading?<h1>Loading</h1>:<Main /> }                      
        </div>
    )
}
export default Home;