import {Container,Row,Col} from 'react-bootstrap';

function Header(){

    return( 
    <header className="header">        
        <Container style={{minWidth:'800px'}}>
            <Row className="justify-content-md-center align-items-end">
                <Col md="auto">
                    <section className="icon-list">
                        <i className="nes-logo"></i>
                        <i className="nes-jp-logo"></i>
                        <i className="snes-logo"></i>
                        <i className="snes-jp-logo"></i>
                    </section>
                </Col>
                <Col md="auto">
                    <section className="icon-list">
                        <i className="nes-mario"></i>
                        <i className="nes-ash"></i>
                        <i className="nes-pokeball"></i>
                        <i className="nes-bulbasaur"></i>
                        <i className="nes-charmander"></i>
                        <i className="nes-squirtle"></i>
                        <i className="nes-kirby"></i>
                    </section>
                </Col>
            </Row>
        </Container>        
    </header>
    )
}

export default Header;