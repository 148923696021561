import {Button} from 'react-bootstrap';

import XLSX from 'xlsx';

function Export({keys,items,hides}){

    const saveFile = async () => {

        try{
            console.log(hides)
            var itemkey = [...keys]
            //console.log(itemkey)
            if(hides.length>0){
                for(var i=0; i<hides.length ;i++){
                    //console.log(hides[i])
                    const idx = itemkey.indexOf(hides[i])
                    itemkey.splice(idx,1)
                }
            }
            //console.log(itemkey)
            var dates = [];            
            items.forEach(r=>{
                var item={};
                itemkey.forEach(key=>{
                    item[key] = r[key];
                })
                dates.push(item)
            })
            console.log(dates)
            const ws = XLSX.utils.json_to_sheet(dates)
            console.log(ws)
            const wb = XLSX.utils.book_new();
            console.log(wb)
            XLSX.utils.book_append_sheet(wb,ws,'Sheet1');
            XLSX.writeFile(wb, "data.xlsx")
        }
        catch(e){
            console.log('---------- '+e);
        }
        
    }

    return(<>
        <Button variant="light" onClick={saveFile}>Export</Button>
    </>)
}

export default Export;