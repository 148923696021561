import {useEffect, useState} from "react";
import {Container,Row,Col,Form} from 'react-bootstrap';
import PocketBase from 'pocketbase';

import Import from './import'
import Export from './export'
import TableList from './tablelist'
import Info from '../info'
const info = Info()

const pb = new PocketBase(info.api);
const keys=info.keys['romlist']

function RomListView(){

  const [togle, setTogle] = useState(false)
  const [items, setItems] = useState([[]]);

  const onTogle = (e)=>{

    console.log(e.target.checked);
    setTogle(e.target.checked);
  }
  useEffect(()=>{    
    
    pb.collection('romlist').getList(1, 1000, {
      filter: 'created >= "2022-01-01 00:00:00"',
    }).then((res)=>{

      console.log( res.items );
      setItems(res.items);
      
      
    }, (err)=>{

      console.log('---------- err : '+ err );
    });

  },[]);

    return (
      <main className="main" >
        <Container>
            <Row>
            <Col md={1}><Export keys={keys} items={items} hides={[]}/></Col>
            <Col md={6}><Import items={items} update={setItems} totle={togle} tableName={'romlist'} edits={{}}/></Col>            
            </Row>
            <Row>
            <Col>
                <Form.Check type="checkbox" label="Import 시 기존 테이블 초기화" onChange={onTogle} />
            </Col>            
            </Row>
        </Container>
        <TableList keys={keys} tableName={'romlist'} items={items} update={setItems} />
      </main>
    );
}

export default RomListView;