import {useEffect} from "react";
import { useLocation } from 'react-router-dom';
import {Container,Row,Col,Nav} from 'react-bootstrap';

import RomListView from "./romlistview"
import NoticeView from "./noticeview"

function Main(){

    const location = useLocation();
    
    const paths = location.pathname.length===1 ? [] : location.pathname.split('/');

    const tablename = paths.length<2 || paths[1].length===0  ? 'romlist' : paths[1]
    
    useEffect(()=>{

        //console.log('location:'+ JSON.stringify(location))
        //console.log('paths : '+paths + ' : '+paths.length)

    },[]);

    return (<>
        <Container style={{width:"100%"}}>
            <Nav defaultActiveKey="/romlist" as="ul">
            <Nav.Item>
                <Nav.Link href="/romlist">Rom</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href="/notice">Notice</Nav.Link>
            </Nav.Item>
        </Nav>
            <Row >
                <Col>
                    { tablename==='notice' ? <NoticeView /> :<RomListView />}
                </Col>
            </Row>
        </Container>
    </>);
}

export default Main;