import {useEffect, useState} from "react";
import {Table,Container,Row,Col,Form,OverlayTrigger,Tooltip,Button,Modal } from 'react-bootstrap';
import PageList from "./pagelist";
import PocketBase from 'pocketbase';
import Info from '../info'
const info = Info()
const pb = new PocketBase(info.api);

function DataList({keys,list, edits}){

  const strLength = 24
  return (
    
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          {keys.map(key=>
              <th key={`th${key}`}>{key}</th>
          )}
        </tr>
      </thead>
      <tbody>
        {
          list.length<1 || list[0].length===0?
            <tr>
              <td key='td1'></td>
              {keys.map(key=>
                <td key={key}></td>
              )}
            </tr>
          :(
            list.map((value,i)=>
            <tr key={`tr${i}`}>
              <td key={`td${i}`}>
                {i+1}
              </td>
              {
                keys.map(key=>
                  <td key={`${i}${key}`} >
                  {                  
                  edits[key]!==undefined
                  ? <Button variant="link" style={{width:'100%'}} onClick={ ()=>edits[key](value) }>{key}</Button>
                  : 
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{value[key]}</Tooltip>}>
                        <div>
                        { value[key].length>strLength ? `${value[key].slice(0,strLength)}...` : value[key] }
                        </div>
                    </OverlayTrigger>
                  }
                  </td>
                )
                
              }
            </tr>
          ))
        }
      </tbody>
    </Table>
  );
}


function TableList({keys,tableName, items, update, edits, onEdit}){

  //console.log('---edits : '+edits )

  const [selectIdx, setSelectIdx] = useState(1);
  const [maxIdx, setMaxIdx] = useState(1);
  const [datas, setdatas] = useState([[]]);

  const [showNew, setShowNew] = useState(false);
  const [showDel, setShowDel] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [modelData, setModelData] = useState({});

  const handleNew = async () =>{
    var upDatas = [...items];
    try{
      const record = await pb.collection(tableName).create(modelData); 
      upDatas.push(record) 
      //console.log(upDatas)
      update(upDatas)
    } catch(e){
      console.log(e);
    }
    setShowNew(false);
  }
  const handleDel = async () =>{
    
    try{
      var upDatas = [];
      //console.log(record)
      for(var i=0; i<items.length; i++){
        if(items[i].id===modelData.id){
          await pb.collection(modelData.collectionName).delete(modelData.id);     
        }else{
          upDatas.push(items[i])
        }
      }
      //console.log(upDatas)
      update(upDatas)
    } catch(e){
      console.log(e);
    } 
    setShowDel(false);
  }
  const handleEdit = async () => {

    try{
      var upDatas = [];
      //console.log(record)
      for(var i=0; i<items.length; i++){
        if(items[i].id===modelData.id){
          const record = await pb.collection(modelData.collectionName).update(modelData.id, modelData);        
          upDatas.push(record)
        }else{
          upDatas.push(items[i])
        }
      }
      //console.log(upDatas)
      update(upDatas)      
    } catch(e){
      console.log(e)
    }
    setShowEdit(false)
  }

  const maxPage = 100;

  edits = {...edits, 
    Edit:(item)=>{

      setModelData({...item})
      setShowEdit(true);
  }, Del:(item)=>{

    setModelData({...item})
    setShowDel(true);
  }}
  useEffect(()=>{
    //console.log(keys);

      let list = [];
      let push = [];
      for(let i=0; i<items.length ;i++){
          
          list.push(items[i]);
          
          if(list.length === maxPage){
              push.push(list);
              list = [];
          }               
      }
      if(list.length>0) push.push(list);
      if(push.length===0) push.push([]);

      let max = push.length===0?1:push.length;

      //console.log(push);

      setdatas(push);
      setMaxIdx(max);     
      setSelectIdx(1);

  },[items,keys])

  return (
    <div className="border p-1">
      <Container>
        <Row className="justify-content-md-center">            
          <Col >
            <Button variant="link" onClick={()=>{
              setModelData({});
              setShowNew(true)
            }}>New</Button>
          </Col>
          <Col xs={10}>
              <PageList maxIdx={maxIdx} onChangePage={(idx)=>{
                setSelectIdx(idx)
              }} />
          </Col>
          <Col ></Col>
        </Row>
        <Row>
          <DataList keys={[...keys,'Edit','Del']}
                list={datas[selectIdx-1]} edits={edits}/>
        </Row>
      </Container>
      <Modal show={showNew} onHide={() => setShowNew(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{modelData.collectionName} New</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
          {              
            keys.map(key =>
              (<div key={`${key}`}>
              <Row >
                {key}
              </Row>
              <Row>
                <Form>
                  <Form.Control as="input" defaultValue={modelData[key]} onChange={(value)=>{
                    //console.log(typeof(modelData[key]))
                    modelData[key] = key==='index' ? Number(value.target.value) :  value.target.value
                    //console.log(modelData)
                  }} ></Form.Control>
                </Form>
              </Row>
              </div>)
            )
          }
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowNew(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleNew}>
            New
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showEdit} onHide={() => setShowEdit(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{modelData.collectionName} Editing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
          {              
            keys.map(key =>
              (<div key={`${key}`}>
              <Row >
                {key}
              </Row>
              <Row>
                <Form>
                  <Form.Control as="input" defaultValue={modelData[key]} onChange={(value)=>{

                    //console.log(typeof(modelData[key]))
                    modelData[key] =  key==='index' ? Number(value.target.value) :  value.target.value
                    //console.log(modelData)
                  }} ></Form.Control>
                </Form>
              </Row>
              </div>)
            )
          }
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEdit(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEdit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDel} onHide={() => setShowDel(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{modelData.collectionName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h1>Delete {modelData.index}</h1>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDel(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleDel}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}


export default TableList;