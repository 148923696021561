import {useEffect, useState,useRef} from "react";
import { useParams,useNavigate } from "react-router-dom";
import {Container,Row,Col,Form,Button} from 'react-bootstrap';
import PocketBase from 'pocketbase';

import './tstory.css';

import Import from './import'
import Export from './export'
import TableList from './tablelist'
import Info from '../info'
const info = Info()

const pb = new PocketBase(info.api);
const keys=info.keys['notice']


function NoticeView(){
    const {id} = useParams()
    let navigate = useNavigate();

    const ref = useRef()

    const [togle, setTogle] = useState(false)
    const [items, setItems] = useState([[]]);
    const [isEdit, setIsEdit] = useState(false);
    const [body, setBody] = useState('');

    const onTogle = (e)=>{

        console.log(e.target.checked);
        setTogle(e.target.checked);
    }
    const onSave = async ()=>{

        //console.log(ref.current.value);
        if(items[0].body !== ref.current.value)
        {
            items[0].body = ref.current.value;
            console.log(items[0].body)
            const record = await pb.collection('notice').update(items[0].id,items[0]);
            console.log(record);
        }
        setIsEdit(false);
    }
    useEffect(()=>{    

        pb.collection('notice').getList(1, 1000, {
            filter: id === undefined ? 'created >= "2022-01-01 00:00:00"' : `created >= "2022-01-01 00:00:00" && index=${id}`,
        }).then((res)=>{

            console.log( res.items );

            
            setItems(res.items);
            setBody(res.items.length===0 ? '' : res.items[0].body);

            
        }, (err)=>{

            console.log('---------- err : '+ err );
        });

    },[id]);

    if(id!==undefined ){
        return (

            <main className="main" >
                <Container>
                    <Row>
                        <Col md={1}><Button variant="link" onClick={()=>navigate(-1)}>{'Back'}</Button></Col>
                        {
                            isEdit
                            ?(<>
                            <Col md={1}><Button onClick={onSave}>{'SAVE'}</Button></Col>                            
                            <Col><Button onClick={()=>setIsEdit(false)}>{'CANCEL'}</Button></Col>
                            </>)
                            :(<Col md={1}><Button onClick={()=>setIsEdit(true)}>{'Edit'}</Button></Col>)
                        }
                    </Row>
                </Container>
                <div className="border p-1 margin m-1 ">
                    <Container style={{height:'100vw'}}>
                        <Row className="justify-content-md-center">                            
                            <Col md='12'>
                            {
                                items.length===0 ? <div>'undefined'</div>:(
                                 isEdit ? (<Form>
                                    <Form.Control ref={ref}  as="textarea" defaultValue={body} style={{width:'96%', height:'96%', minHeight:'1000px'}} ></Form.Control>
                                 </Form>) 
                                 :(<div dangerouslySetInnerHTML={ {__html: body} }></div>)    
                                )
                            }
                            </Col>
                        </Row>
                    </Container>
                </div>
            </main>


        )
    }

    return(
        <main className="main" >
            <Container>
                <Row>
                <Col md={1}><Export keys={keys} items={items} hides={['body']}/></Col>
                <Col md={6}><Import items={items} update={setItems} totle={togle} tableName={'notice'} /></Col>            
                </Row>
                <Row>
                <Col>
                    <Form.Check type="checkbox" label="Import 시 기존 테이블 초기화" onChange={onTogle} />
                </Col>            
                </Row>
            </Container>
            <TableList keys={keys} tableName={'notice'} items={items} update={setItems} edits={
                { body:(item)=>{

                    navigate(`/notice/${item.index}`)
                }}}/> 
            
      </main>
    );
}

export default NoticeView;