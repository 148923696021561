

function Info() {
    return {        
        api : 'https://5rbpdiisaa.execute-api.ap-northeast-2.amazonaws.com',
        //api : 'https://boea5jvzjh.execute-api.ap-northeast-2.amazonaws.com',
        //api : "https://75eyhovt3b.execute-api.ap-northeast-2.amazonaws.com",
        //api : 'http://152.70.241.134:8090',
        //api : 'http://193.122.98.43:8090',        
        //api : 'https://emulator-charge.pockethost.io',
        keys:{
            notice:['index','title','des','img','body'],
            romlist:['index','name','type','title','release','genre','dev','des','core','file','img']
        },
    }
}

export default Info 