import {useRef,useEffect,useState} from 'react'
import {Button} from 'react-bootstrap';
import PocketBase from 'pocketbase';

import XLSX from 'xlsx';
import Info from '../info'
const info = Info()


const pb = new PocketBase(info.api);


function Import({items,update,togle,tableName}){

    
    const [loadstring,setLoadString] = useState("");
    const ref = useRef()
    

    const uploadItems = async (datas) => {

        console.log(items);
        var indexMap = {};
        items.forEach(i=>{
            indexMap[i.index.toString()] = i.id
        })
        console.log(indexMap)        
        
        var upDatas = [];
        for(var i=0; i<datas.length ;i++){

            var id = datas[i].index.toString();
            console.log(indexMap[id]);
            //console.log(datas[i]);
            if( indexMap[id] === undefined  ){
                
                const record = await pb.collection(tableName).create(datas[i]);
                //console.log(record);                
                upDatas.push(record);
                setLoadString('create : '+JSON.stringify(datas[i]))
            }else{
                const record = await pb.collection(tableName).update(indexMap[id], datas[i]);        
                //console.log(record); 
                upDatas.push(record);       
                setLoadString('update : '+JSON.stringify(datas[i]))
            }
            delete indexMap[id];
        }
        console.log(indexMap)

        if(togle){
            Object.keys(indexMap).forEach(async key=>{
                
                await pb.collection('romlist').delete(indexMap[key]);
                console.log(`delete id=${indexMap[key]}, index=${key}`)
                setLoadString(`delete id=${indexMap[key]}, index=${key}`)
            })
        }       

        update(upDatas);

        setLoadString('')
    }
    const loadFile = async files => {

        //console.log(files);

        if(files.length>0){
            const file = files[0];

            const reader = new FileReader();
            reader.onload = async (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: "array", bookVBA: true });
    
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet);
                
                console.log(jsonData)

                uploadItems(jsonData);
            };
            reader.readAsArrayBuffer(file);
        }
        ref.current.value = '';
    }

    useEffect(()=>{

        //console.log(items);
        

    },[items])

    return(<>
        <Button variant="light" onClick={()=>ref.current.click()}>Import
            <input
            type="file"
            accept=".xlsx, .xls, .csv"
            ref={ref}
            onChange={(e) => loadFile(e.target.files)}
            style={{display:'none'}} />
        </Button>
        <div>{loadstring.length===0 ? '': `${loadstring.slice(0,64)}...`}</div>
    </>)
}

export default Import;