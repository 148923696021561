import { useState} from 'react';
import { useNavigate } from "react-router-dom";
import {Button, Form} from 'react-bootstrap';
import Header from "../components/header"

import PocketBase from 'pocketbase';
import Info from '../info'
const info = Info()

const pb = new PocketBase(info.api);

function Login() {
	const [Id, setId] = useState('');
	const [Pw, setPw] = useState('');

	let navigate = useNavigate();
	const handleLogin = async ()=> {

		console.log('login onClick');
		console.log(`${Id}, ${Pw}`);

		try {
			await pb.collection('users').authWithPassword(
				Id,
				Pw,
			);
			//console.log(authData);
			console.log(pb.authStore.isValid);		

			if(pb.authStore.isValid){

				console.log(pb.authStore.token);
				console.log(pb.authStore.model.id);
				sessionStorage.setItem('token',pb.authStore.token);
				navigate("/");
			}
		}
		catch(e){
			console.log(e);
		}
	}
  return (
	<div>
		<Header />
		<div className="d-flex align-items-center  
                        justify-content-center vh-100"> 
			<Form>
			<Form.Group className="mb-3" controlId="formBasicAccount">
				<Form.Label>Account</Form.Label>
				<Form.Control type="account" placeholder="Enter Account" onChange={e=>setId(e.target.value)} />
			</Form.Group>
			<Form.Group className="mb-3" controlId="formBasicPassword">
				<Form.Label>Password</Form.Label>
				<Form.Control type="password" placeholder="Password" onChange={e=>setPw(e.target.value)}/>
			</Form.Group>
			<Button variant="primary" onClick={handleLogin}>
				Login
			</Button>
			</Form>					
		</div>
	</div>
	
    
  );
}

export default Login    